import { Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { AppUtils } from 'src/app/core/app-utils';
import { DateUtils } from 'src/app/core/date-utils';
import { CompanyLogoDTO, CompanySettingDTO } from 'src/app/core/models/company';
import { MemberDTO } from 'src/app/core/models/member';
import { CompanyService } from 'src/app/core/services/company.sevice';
import { MemberService } from 'src/app/core/services/member.service';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';
import { MessageService } from 'src/app/core/services/shared-services/message.service';
import { NotificationService } from 'src/app/core/services/shared-services/notification.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { dateFormat } from '../../app.constants';
import { AlertModalComponent } from '../../modal/alert-modal/alert-modal.component';
import { HelpModalComponent } from '../../modal/help-modal/help-modal.component';
import { NotificationComponent } from '../../modal/notification/notification.component';
import { UserDetailModalComponent } from '../../modal/user-detail-modal/user-detail-modal.component';

@Component({
  selector: 'eb-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('globalErrorContainer', { static: true, read: ViewContainerRef }) errorContainer: ViewContainerRef;
  message: string = 'Click!';
  isClicked: boolean = false;
  modalRef: any | MdbModalRef<any>;
  userEmail!: string;
  userGUID!: string;
  userFName!: string;
  userCompanyName!: string;
  userIsEdenRedAdmin = false;
  actingOnLabel = 'Acting On';
  selectedCompanyName: any = '';
  selectedCompany: any;
  selectedCompanyCode: any = '';
  chooseCompanyLabel = 'Select a Company';
  isCustomerPortalVisible: boolean = false;
  hasCstmrPrtlAccess: boolean = false;
  previousUrl: string = '';
  companySettingsData: CompanySettingDTO | undefined;
  fullDate: string = '';
  fullName: string = '';
  isAuthenticated: boolean = false;
  @Input() unreadAlertCount: number = 0;
  @Input() isCardMenu: boolean = false;
  @Input() isElectBenefitMenu: boolean = false;
  @Input() isMarketMenu: boolean = false;
  @Input() isClaimMenu: boolean = false;
  @Input() isParkingMenu: boolean = false;
  @Input() isMarketPlaceMenu: boolean = false;
  @Input() isDashboardMenu: boolean = false;
  isReserveParking: boolean = false;
  isNavBarVisible = false;
  cartProductsCount = 0;
  companySubscription: Subscription;
  shoppingCartSubscription: Subscription;
  memberSubscription: Subscription;
  logoUrl = '';
  isTrnPrkPCCCAllow: boolean;
  notificationList: any;
  activeNotificationCount = 0;
  amazonNotEligible = false;
  isAmznDiffLocation = false;
  isLSARole: boolean;
  isCRRole: boolean;
  isTrRole: boolean;
  hideElectBenefits = false;
  memberSettings: MemberDTO;
  constructor(
    private modalService: MdbModalService,
    private router: Router,
    private messageService: MessageService,
    private authService: AuthService,
    private companyService: CompanyService,
    private shoppingCartService: ShoppingCartService,
    private notificationService: NotificationService,
    private memberService: MemberService
  ) {
    this.messageService.activeObservable$.subscribe((res: any) => {
      if (res.refresh) {
        this.isReserveParking = res.refresh;
      }
    });
  }

  ngOnInit(): void {
    this.isLSARole = AppUtils.isInRoles('LsaRole');
    this.isCRRole = AppUtils.isInRoles('CRRole');
    this.isTrRole = AppUtils.isInRoles('TRRole');

    this.notificationService.globalTargetElement = this.errorContainer;
    this.isAuthenticated = this.authService.isAuthenticated;
    this.fullName = this.authService.getFullname();

    this.getMemberSettings();

    this.shoppingCartSubscription = this.shoppingCartService.cartProductsCount$.subscribe(res => {
      this.cartProductsCount = res;
    });

    this.memberService.markAsReadNotification$.subscribe(res => {
      if (res) {
        this.loadMemberNotifications();
      }
    });
  }
  loadMemberNotifications(): void {
    this.memberSubscription = this.memberService
      .getMemberNotifications(AppUtils.memberId, this.companySettingsData.idTpa, this.companySettingsData.idCompany)
      .subscribe(resp => {
        if (resp) {
          this.notificationList = resp;
          this.activeNotificationCount = this.notificationList.filter(x => !x.isReadByMember).length;
          if (this.notificationList.length > 0) {
            this.notificationList.forEach(element => {
              element.publishDate = DateUtils.FromUnix(element.publishDate);
              element.expiryDate = DateUtils.FromUnix(element.expiryDate);
              const initialSubject = element.boday
                .replace(/<[^>]*>/g, '')
                .replace('&nbsp;', ' ')
                .split(' ');
              if (initialSubject.length > 5) {
                element.body = initialSubject.slice(0, 15).join(' ');
              } else {
                element.body = initialSubject.join(' ');
              }
            });

            this.notificationService.setNotificationData = this.notificationList;
          }
        }
      });
  }
  navigateToPage(item: any) {
    switch (item) {
      case 'Dashboard': {
        if (AppUtils.appRoles && AppUtils.appRoles.length > 0) {
          if (AppUtils.appRoles.indexOf('LsaRole') > -1) {
            this.router.navigateByUrl('/dashboard/lsa');
          } else {
            this.router.navigateByUrl('/dashboard/home');
          }
        } else {
          this.router.navigateByUrl('/dashboard/home');
        }
        break;
      }
      case 'Cards': {
        this.router.navigateByUrl('/card/home');
        break;
      }
      case 'ElectBenefits': {
        this.router.navigateByUrl('/elect-benefits/home');
        break;
      }
      case 'Claims': {
        this.router.navigateByUrl('/claim/home');
        break;
      }
      case 'Market Place': {
        this.router.navigateByUrl('/marketplace/marketplace');
        break;
      }
      case 'Parking': {
        this.router.navigateByUrl('/parking/parking');
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  showAlertPopup() {
    this.modalRef = this.modalService.open(AlertModalComponent, {
      data: {},
      modalClass: 'modal-help modal-dialog modal-fullscreen-lg-down modal-alert',
    });
    this.modalRef.onClose.subscribe((message: string) => {
      if (message == 'MarkApplicationComplete') {
      }
    });
  }
  showUserDetailPopup() {
    this.modalRef = this.modalService.open(UserDetailModalComponent, {
      data: {},
      modalClass: 'modal-help modal-dialog modal-fullscreen-lg-down messageModal',
    });
    this.modalRef.onClose.subscribe((message: string) => {
      if (message == 'MarkApplicationComplete') {
      }
    });
  }
  showHelpMenuPopup() {
    this.modalRef = this.modalService.open(HelpModalComponent, {
      data: {},
      modalClass: 'modal-help modal-circle modal-dialog modal-fullscreen-lg-down messageModal',
    });
    this.modalRef.onClose.subscribe((message: string) => {
      if (message == 'MarkApplicationComplete') {
      }
    });
  }
  showNotificationPopup() {
    this.modalRef = this.modalService.open(NotificationComponent, {
      // data: { notificationList: this.notificationList },
      modalClass: 'modal-help modal-circle modal-dialog modal-fullscreen-lg-down messageModal',
    });
    this.modalRef.onClose.subscribe((message: string) => {
      if (message == 'MarkApplicationComplete') {
      }
    });
  }
  openProfileMenu() {
    //this.modalRef = this.modalService.open(ProfileMenuComponent, {
    //  data: {
    //    userFName: this.userFName,
    //    userCompanyName: this.userCompanyName,
    //    userGUID: this.userGUID
    //  },
    //  ModalCssClass: 'menu-modal',
    //  ignoreBackdropClick: false
    //});
  }

  openHelpMenu() {
    //this.modalRef = this.modalService.open(HelpMenuComponent, {
    //  data: {
    //  },
    //  ModalCssClass: 'menu-modal',
    //  ignoreBackdropClick: false
    //});
  }

  openAlertsMenu() {}

  isCustomerPortal() {}

  getCompanySettingsData(): void {
    this.companySubscription = this.companyService.companySettings$?.subscribe(
      (companySettingsData: CompanySettingDTO) => {
        if (companySettingsData) {
          this.companySettingsData = companySettingsData;
          this.isTrnPrkPCCCAllow = this.companyService.isTrnPrkPCCCAllow;
          const lastChanceToOrderDate = new Date(this.companySettingsData.lastChanceToOrder);
          this.fullDate = DateUtils.getFormattedDate(lastChanceToOrderDate, dateFormat.MMDDYYYY);
          this.getCompanyLogo(this.companySettingsData.idCompany, this.companySettingsData.idTpa);
          this.loadMemberNotifications();
          if (this.isLSARole || this.isTrRole) {
            if (this.companySettingsData?.tpaCompanyId === 'ROTC') {
              this.isNavBarVisible = true;
            } else {
              this.isNavBarVisible = false;
            }
          } else {
            this.isNavBarVisible = true;
          }
          if (this.isLSARole) {
            this.hideElectBenefits = true;
          }
          if (this.companySettingsData?.tpaCompanyId == 'AMZN') {
            if (
              !this.memberSettings?.trnIsAllow &&
              !this.memberSettings?.prkIsAllow &&
              !this.memberSettings?.prkdpIsAllow &&
              !this.isCRRole &&
              !this.companySettingsData?.prkcoIsAllow
            ) {
              this.amazonNotEligible = true;
              this.router.navigateByUrl('/dashboard/notEligible');
            } else if (!this.memberSettings?.trnIsAllow && !this.memberSettings?.prkIsAllow) {
              if (this.isCRRole) {
                this.isAmznDiffLocation = true;
              }
            }
          }
        }
      }
    );
  }
  getCompanyLogo(idCompany: number, idTpa: number): void {
    this.companySubscription = this.companyService
      .getTPACompanyLogo(idCompany, idTpa)
      .subscribe((res: CompanyLogoDTO) => {
        if (res) {
          if (res.logo) {
            this.logoUrl = 'data:image/png;base64, ' + res.logo;
          } else {
            this.logoUrl = 'assets/images/edenred_logo_new.svg';
          }
        } else {
          this.logoUrl = 'assets/images/edenred_logo_new.svg';
        }
      });
  }
  getMemberSettings() {
    this.memberSubscription = this.memberService.member$.subscribe(memberDetails => {
      if (memberDetails) {
        this.memberSettings = memberDetails;
        this.getCompanySettingsData();
      }
    });
  }
  ngOnDestroy() {
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
    if (this.shoppingCartSubscription) {
      this.shoppingCartSubscription.unsubscribe();
    }
    if (this.memberSubscription) {
      this.memberSubscription.unsubscribe();
    }
  }
  navigateToReviewOrder(): void {
    this.router.navigateByUrl('/elect-benefits/review-order');
  }
}
