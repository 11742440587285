import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { AppUtils } from 'src/app/core/app-utils';
import { CompanySettingDTO } from 'src/app/core/models/company';
import { MemberNotificationDTO } from 'src/app/core/models/member';
import { CompanyService } from 'src/app/core/services/company.sevice';
import { MemberService } from 'src/app/core/services/member.service';
import { NotificationService } from 'src/app/core/services/shared-services/notification.service';
import { ButtonText, ModalCssClass } from '../../app.constants';
import { MessageComponent } from '../message/message.component';
import { DateUtils } from 'src/app/core/date-utils';
import * as moment from 'moment';

@Component({
  selector: 'eb-notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent implements OnInit, OnDestroy, AfterViewInit {
  notificationList: any;
  activeNotificationList: any = [];
  notificationArchivedList: any = [];
  companySubscription: Subscription;
  company: CompanySettingDTO = {};
  memberSubscription: Subscription;
  modalRefNotificationDetail: any | MdbModalRef<MessageComponent>;
  activeNotificationCount = 0;
  @Output() emitNotificationService = new EventEmitter();
  constructor(
    public modalRef: MdbModalRef<NotificationComponent>,
    private companyService: CompanyService,
    private notificationService: NotificationService,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.filterNotificationData();
    this.companySubscription = this.companyService.companySettings$?.subscribe(res => {
      this.company = res;
    });
  }
  filterNotificationData() {
    if (this.notificationService?.getNotificationData) {
      this.notificationList = this.notificationService.getNotificationData;
    }
    this.activeNotificationCount = this.notificationList.filter(x => !x.isReadByMember).length;
    this.activeNotificationList = this.notificationList.filter(activeList => !activeList?.isReadByMember);
    this.notificationArchivedList = this.notificationList.filter(archivedList => archivedList?.isReadByMember);
  }

  openMessageModal = (notification: MemberNotificationDTO) => {
    this.modalRefNotificationDetail = this.notificationService.openPopupMessage(
      notification?.subject,
      notification?.boday,
      true,
      ButtonText.Close,
      ModalCssClass.LargeModal,
      true
    );
    this.modalRefNotificationDetail.onClose.subscribe(response => {
      if (response) {
        this.filterNotificationData();
      }
    });
  };
  closeModal(): void {
    this.modalRef.close('');
  }
  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    if (this.memberSubscription) {
      this.memberSubscription.unsubscribe();
    }
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }
  markAsRead(notification: MemberNotificationDTO): void {
    if (!notification.isReadByMember) {
      this.memberSubscription = this.memberService
        .getSayNotificationRead(notification.idAnnouncement, AppUtils.memberId)
        .subscribe(resp => {
          if (resp) {
            this.memberService.markAsReadNotification$.next(resp);
          }
        });
    }
  }
  markAllAsRead(): void {
    if (this.notificationList && this.notificationList.length > 0) {
      const activeNotification = this.notificationList.filter(x => !x.isReadByMember);
      activeNotification.forEach(element => {
        this.markAsRead(element);
      });
    }
  }

  openNotification(notificationPopUp: MemberNotificationDTO): void {
    this.markAsRead(notificationPopUp);
    this.openMessageModal(notificationPopUp);
  }

  getExpiryDay(expiryDate: Date): number {
    const presentDay = moment();
    let notificationExpiryDate = moment(expiryDate);
    let daysLeft = notificationExpiryDate.diff(presentDay, 'days');
    if (notificationExpiryDate.diff(presentDay, 'hours') > 0) {
      daysLeft += 1;
    }
    return daysLeft;
  }
}
