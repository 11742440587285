import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'eb-message',
  templateUrl: './message.component.html',
})
export class MessageComponent implements OnInit {
  title!: string;
  message!: string;
  footerNote!: string;
  isHTML = false;
  showActionButton = false;
  showCancelButton = false;
  btnText!: string;
  cancelText!: string;
  messageToSendOnClose!: string;
  tabIndex!: number;

  constructor(public modalRef: MdbModalRef<MessageComponent>) {}

  ngOnInit(): void {}
  raiseCancelEvent() {
    setTimeout(() => {
      this.modalRef.close(true);
    }, 100);
  }
  raiseEvent() {
    setTimeout(() => {
      this.modalRef.close(this.messageToSendOnClose);
    }, 100);
  }
}
