<div>
  <eb-navbar></eb-navbar>
</div>
<br />
<div class="f-inner">
  <div class="eb-cards row mx-0 mb-md-8">
    <div class="col-xl-6 pl-lg-2 pr-lg-2 pl-0 pr-0">
      <div class="border-0 card pl-0 pl-lg-2 pr-lg-2 pt-3 mb-md-8">
        <div class="align-items-baseline row mx-0 mt-2">
          <div class="col-9 col-lg-9">
            <div class="d-flex">
              <div class="ml-3">
                <h3 class="font-weight-600 mb-0 pt-1">Instructional Videos</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="align-items-baseline row mx-0 mt-2">
          <p class="font-weight-400 mb-0 pt-1 ml-3 font-16">View our short instructional videos on using the portal.</p>
        </div>
        <div class="row mx-0 m-2 m-2 mb-3 mb-lg-3" style="background-color: #f1f7ff !important; border-radius: 16px">
          <div class="row mx-0 mt-2 mb-4">
            <div class="col-4 col-xl-4">
              <div class="row mx-0">
                <div
                  class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer"
                  tabindex="11"
                  (keyup.enter)=" openVideoPlayer(
                    'Portal Tour',
                    'https://edenredbenefits.com/wp-content/uploads/2024/02/512b3e92-5f96-4850-a040-7e798453e9db-1.mp4'
                  )"
                  (click)="
                    openVideoPlayer(
                      'Portal Tour',
                      'https://edenredbenefits.com/wp-content/uploads/2024/02/512b3e92-5f96-4850-a040-7e798453e9db-1.mp4'
                    )
                  ">
                  <img src="assets/images/PortalTour.png" alt="Portal Tour" /><span class="ml-1 mb-2 mt-2"
                    >Portal Tour</span
                  >
                </div>
              </div>
            </div>
            <div class="col-4 col-xl-4" *ngIf="isTrnPrkPCCCAllow">
              <div class="row mx-0">
                <div
                  class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer"
                  tabindex="11"
                  (keyup.enter)="openVideoPlayer(
                    'Managing Your Card',
                    'https://edenredbenefits.com/wp-content/uploads/2024/07/manage-card_trim.mov'
                  )"
                  (click)="
                    openVideoPlayer(
                      'Managing Your Card',
                      'https://edenredbenefits.com/wp-content/uploads/2024/07/manage-card_trim.mov'
                    )
                  ">
                  <img src="assets/images/ManageYourCard.png" alt="Managing Your Card" /><span class="ml-1 mb-2 mt-2"
                    >Managing Your Card</span
                  >
                </div>
              </div>
            </div>
            <div class="col-4 col-xl-4" *ebAppRole="'LsaRole'; show: false">
              <div class="row mx-0">
                <div
                  class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer"
                  tabindex="11"
                  (keyup.enter)="openVideoPlayer(
                    'Electing Benefits',
                    'https://edenredbenefits.com/wp-content/uploads/2024/07/Elect-Benefits-Tutorial-Final-1.mov'
                  )"
                  (click)="
                    openVideoPlayer(
                      'Electing Benefits',
                      'https://edenredbenefits.com/wp-content/uploads/2024/07/Elect-Benefits-Tutorial-Final-1.mov'
                    )
                  ">
                  <img src="assets/images/ElectingYourBenefit.png" alt="Electing Benefits" />
                  <span class="ml-1 mb-2 mt-2">Electing Benefits</span>
                </div>
              </div>
            </div>

            <div class="col-4 col-xl-4" *ngIf="parkingCRAllow">
              <div class="row mx-0">
                <div
                  class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer"
                  tabindex="11"
                  (keyup.enter)="openVideoPlayer(
                    'Reimbursements',
                    'https://edenredbenefits.com/wp-content/uploads/2024/07/reimbursement-method.mov'
                  )"
                  (click)="
                    openVideoPlayer(
                      'Reimbursements',
                      'https://edenredbenefits.com/wp-content/uploads/2024/07/reimbursement-method.mov'
                    )
                  ">
                  <img src="assets/images/SetUpReimbursement.png" alt="Reimbursements" />
                  <span class="ml-1 mb-2 mt-2">Reimbursements</span>
                </div>
              </div>
            </div>
            <div class="col-4 col-xl-4" *ngIf="showPersonalCardvideo">
              <div class="row mx-0">
                <div
                  class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer"
                  tabindex="11"
                  (keyup.enter)="openVideoPlayer(
                    'Updating Personal Credit Card',
                    'https://edenredbenefits.com/wp-content/uploads/2024/07/Update-personal-credit-card-info.mov'
                  )"
                  (click)="
                    openVideoPlayer(
                      'Updating Personal Credit Card',
                      'https://edenredbenefits.com/wp-content/uploads/2024/07/Update-personal-credit-card-info.mov'
                    )
                  ">
                  <img src="assets/images/UpdatePersonalCardInfo.png" alt="Updating Personal Credit Card" />
                  <span class="ml-1 mb-2 mt-2">Updating Personal Credit Card</span>
                </div>
              </div>
            </div>
            <div class="col-4 col-xl-4" *ngIf="prkcrIsAllow">
              <div class="row mx-0">
                <div
                  class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer"
                  tabindex="11"
                  (keyup.enter)="openVideoPlayer(
                    'Transferring Funds',
                    'https://edenredbenefits.com/wp-content/uploads/2024/07/transfer-funds.mov'
                  )"
                  (click)="
                    openVideoPlayer(
                      'Transferring Funds',
                      'https://edenredbenefits.com/wp-content/uploads/2024/07/transfer-funds.mov'
                    )
                  ">
                  <img src="assets/images/BalanceTransferToParkingAccount.png" alt="Transferring Funds" />
                  <span class="ml-1 mb-2 mt-2">Transferring Funds</span>
                </div>
              </div>
            </div>

            <div class="col-4 col-xl-4" *ngIf="allowMobileWallet && isActiveCard">
              <div class="row mx-0">
                <div
                  class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer"
                  tabindex="11"
                  (keyup.enter)="openVideoPlayer(
                    'Enabling Digital Wallet',
                    'https://edenredbenefits.com/wp-content/uploads/2024/07/mobile-wallet.mov'
                  )"
                  (click)="
                    openVideoPlayer(
                      'Enabling Digital Wallet',
                      'https://edenredbenefits.com/wp-content/uploads/2024/07/mobile-wallet.mov'
                    )
                  ">
                  <img src="assets/images/SetUpMobileWallet.png" alt="Enabling Digital Wallet" />
                  <span class="ml-1 mb-2 mt-2">Enabling Digital Wallet</span>
                </div>
              </div>
            </div>
            <div class="col-4 col-xl-4" *ngIf="allowCompanyOfferedParking">
              <div class="row mx-0">
                <div
                  class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer"
                  tabindex="11"
                  (keyup.enter)="openVideoPlayer(
                    'Enroll in Employer Managed parking',
                    'https://edenredbenefits.com/wp-content/uploads/2024/07/Employer-Managed-Parking.mp4'
                  )"
                  (click)="
                    openVideoPlayer(
                      'Enroll in Employer Managed parking',
                      'https://edenredbenefits.com/wp-content/uploads/2024/07/Employer-Managed-Parking.mp4'
                    )
                  ">
                  <img src="assets/images/EnrollEmpManagedParking.png" alt="Enroll in Employer Managed parking" />
                  <span class="ml-1 mb-2 mt-2">Enroll in Employer Managed parking</span>
                </div>
              </div>
            </div>
            <div class="col-4 col-xl-4" *ngIf="isClaimPresent">
              <div class="row mx-0">
                <div
                  class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer"
                  tabindex="11"
                  (keyup.enter)="openVideoPlayer(
                    'Filing a Claim',
                    'https://edenredbenefits.com/wp-content/uploads/2024/07/Filing-a-claim.mov'
                  )"
                  (click)="
                    openVideoPlayer(
                      'Filing a Claim',
                      'https://edenredbenefits.com/wp-content/uploads/2024/07/Filing-a-claim.mov'
                    )
                  ">
                  <img src="assets/images/FillingClaim.png" alt="Filing a Claim" />
                  <span class="ml-1 mb-2 mt-2">Filing a Claim</span>
                </div>
              </div>
            </div>

            <div class="col-4 col-xl-4">
              <div class="row mx-0">
                <div
                  class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer"
                  tabindex="11"
                  (keyup.enter)="openVideoPlayer(
                    'Customer Support',
                    'https://edenredbenefits.com/wp-content/uploads/2024/08/customer-support.mov'
                  )"
                  (click)="
                    openVideoPlayer(
                      'Customer Support',
                      'https://edenredbenefits.com/wp-content/uploads/2024/08/customer-support.mov'
                    )
                  ">
                  <img src="assets/images/ContactCustomerSupport.png" alt="Contacting Customer Support" />
                  <span class="ml-1 mb-2 mt-2">Contacting Customer Support</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-xl-6 mt-3 mt-lg-3 pl-0 pl-lg-2 pr-lg-2 pr-0 mt-xl-0"
      *ngIf="isFlexPurseEnabled || isAlight || isEmpResGuide">
      <div class="border-0 card pl-0 pl-lg-2 pr-lg-2 pt-3 mb-md-8">
        <div class="align-items-baseline row mx-0 mt-2">
          <div class="col-9 col-lg-9">
            <div class="d-flex">
              <div class="ml-3">
                <h3 class="font-weight-600 mb-0 pt-1">Guides</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-0 m-2 m-2 mb-3 mb-lg-3" style="background-color: #f1f7ff !important; border-radius: 16px">
          <div class="row mx-0 mt-2 mb-4">
            <div class="col-4 col-xl-4" *ngIf="isFlexPurseEnabled">
              <div class="row mx-0">
                <div class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer" tabindex="12" (keyup.enter)="openGuide('ITPF')" (click)="openGuide('ITPF')">
                  <img src="assets/images/Guide.png" alt="Portal Tour" /><span class="ml-1 mb-2 mt-2"
                    >Introducing Transit and Parking Flex</span
                  >
                </div>
              </div>
            </div>
            <div class="col-4 col-xl-4" *ngIf="isAlight">
              <div class="row mx-0">
                <div class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer" tabindex="12" (keyup.enter)="openGuide('ERG')" (click)="openGuide('ERG')">
                  <img src="assets/images/Guide.png" alt="Portal Tour" /><span class="ml-1 mb-2 mt-2"
                    >Employee Resource Guide</span
                  >
                </div>
              </div>
            </div>
            <div class="col-4 col-xl-4" *ngIf="isEmpResGuide">
              <div class="row mx-0">
                <div class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer" tabindex="12" (keyup.enter)="openGuide('MAQ')" (click)="openGuide('MAQ')">
                  <img src="assets/images/Guide.png" alt="Portal Tour" /><span class="ml-1 mb-2 mt-2"
                    >Mobile App Quick Start Guide</span
                  >
                </div>
              </div>
            </div>
            <div class="col-4 col-xl-4" *ngIf="isEmpResGuide">
              <div class="row mx-0">
                <div class="col-12 col-lg-12 mt-4 mt-lg-4 card cursor-pointer" tabindex="12" (keyup.enter)="openGuide('ERG2')" (click)="openGuide('ERG2')">
                  <img src="assets/images/Guide.png" alt="Portal Tour" /><span class="ml-1 mb-2 mt-2"
                    >Employee Resource Guide</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
